import { createWebHistory, createRouter } from 'vue-router';
import CompressionTool from '../views/CompressionTool.vue'
import MergeTool from '../views/MergeTool.vue';
import SplitTool from '../views/SplitTool.vue';
import NUpTool from '../views/N-UpTool.vue';
import EncryptTool from '../views/EncryptTool.vue';
import utils from '../utils';

const routes = [{
    path: utils.globalPath(''),
    component: MergeTool,
}, {
    path: utils.globalPath('compression-tool'),
    component: CompressionTool,
}, {
    path: utils.globalPath('merge-tool'),
    component: MergeTool,
}, {
    path: utils.globalPath('split-tool'),
    component: SplitTool,
}, {
    path: utils.globalPath('nup-tool'),
    component: NUpTool,
}, {
    path: utils.globalPath('encrypt-tool'),
    component: EncryptTool,
}];

const router = createRouter({
    history: createWebHistory(),
    routes, linkActiveClass: 'active',
});

router.beforeEach((to, from) => { return true; });
export default router;