<template>
    <UploaderTool :uploaderConfig="uploaderConfig" v-if="!loadedFiles.length" :acceptUpload="acceptUpload"/>

    <template v-else>
        <ChatSidebar v-if="$store.state.isEnabledChat"/>
        <div class="wrapper header-fixed sidebar-left-fixed sidebar-right-fixed mobile-actions-fixed" :class="{ 'full-width-hidden-chat': !$store.state.isEnabledChat }">
            <main class="content fullscreen-page">
                <div class="content-header">
                    <DocumentRename :name="documentName" @inputName="documentName = $event"/>

                    <!-- <div class="content-header-item">
                        <div class="buttons-group">
                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/minus-icon.svg')" alt="">
                            </a>

                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/plus-icon.svg')" alt="">
                            </a>
                        </div>

                        <p class="text-14 font-normal">1 / 9</p>
                    </div> -->
                </div>
                
                <div class="content-body scroll-block">
                    <div class="container">
                        <draggable v-model="loadedFiles" item-key="filename" class="row doc-list --ordered" @start="drag = true" @end="drag = false">
                            <template #item="{element}">
                                <PreviewDocumentPage
                                    :preview="previewDocument(element)"
                                    :rotateAngle="element?.angle || 0"
                                    :name="element.name"
                                    @close="$store.dispatch('deleteFile', element.filename)"
                                    @rotate="rotateFile(element.filename)"
                                />
                            </template>
                        </draggable>
                    </div>
                </div>
            </main>

            <MobilePageActions/>            
            <Footer :uploaderConfig="uploaderConfig" :acceptUpload="acceptUpload"/>
        </div>

        <aside class="sidebar sidebar--right" :class="{ active: isOpenedSidebar }">
            <div class="sidebar-header">
                <p class="def-text-1 font-[500]">Merge PDF</p>
                <CloseMobileSidebars/>
            </div>

            <div class="sidebar-body">
                <div class="card card-secondary">
                    <div class="card-body" v-if="isDisabledProcess">
                        <p>
                            Please, select more PDF files by clicking again on ’Select PDF files’. 
                        </p>
                        <p>Select multiple files by mantaining pressed ’Ctrl’</p>
                    </div>

                    <div class="card-body" v-else>
                        <p>
                            To change the order of your PDFs, drag and drop the files as you want.
                        </p>
                    </div>
                </div>
            </div>
            <div class="sidebar-footer pt-0">
                <ButtonProcess text="Merge PDF" @click="processing" :isDisabledProcess="isDisabledProcess"/>
            </div>
        </aside>
    </template>
</template>

<script>
import toolMixin from '../mixins/toolMixin';

export default {
    mixins: [toolMixin],
    data: () => ({
        toolName: 'merge',
        documentName: 'Meged Documents',
        uploaderConfig: {
            title: 'Merge PDF Files',
            description: 'Combine multiple PDF files into one PDF',

            processFileOpts: {
                isSinglePreview: true
            }
        },

        downloadWindowConfig: {
            title: 'PDF has been merged!',
            buttonDownloadText: 'Download Merged PDF',
            backButtonText: 'Back to Merge PDF'
        },
    }),

    methods: {
        async processLocalyTool() {
            const filesConfig = this.loadedFiles.map(lf => {
                let _rotatedPages = this.parseRotatedPages(lf);
                const _deletedPages = [];

                return {
                    name: lf.filename,
                    config: { _rotatedPages, _deletedPages }
                };
            });

            return window.app.components.merge.apply(this.processFiles, {
                isDownload: false,
                files: filesConfig
            });
        }
    }
}
</script>