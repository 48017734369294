const downloadjs = require('downloadjs');

export default {
    globalPath(path) {
        return process.env.BASE_URL + path;
    },

    generatePreviewsOfPDF(uploadedFile, store, { file, opts }) {
        window.pdfjsLib.getDocument(URL.createObjectURL(uploadedFile._file)).promise.then(async pdfDoc => {
            const localStoreFile = store.state.loadedFiles.find(lf => lf.filename === file.filename);
            localStoreFile.numPages = pdfDoc.numPages;
            localStoreFile.pages = [];

            // optimization load pages
            const pagesRenderPreview = opts.isSinglePreview ? 1 : pdfDoc.numPages;
            window.pdfDoc = pdfDoc;
            for (let i = 0; i < pagesRenderPreview; i++) {
                const timerStringDebug = `Render page ${file.filename} [${i + 1}]`;
                await new Promise(resolve => {
                    setTimeout(async () => {
                        console.time(timerStringDebug);

                        const pw = 200;
                        const ph = 200;
                        const scale = 1;

                        const outputScale = window.devicePixelRatio || 1;
                        const page = await pdfDoc.getPage(i + 1);
                        const viewport = await page.getViewport({ scale });
                        const canvas = document.createElement('canvas');
                        const canvasContext = canvas.getContext('2d');

                        const scalePreview = Math.min(
                            pw / viewport.width,
                            ph / viewport.height
                        );

                        const previewViewport = await page.getViewport({ scale: scalePreview });
                        canvas.width = Math.floor(previewViewport.width * outputScale);
                        canvas.height = Math.floor(previewViewport.height * outputScale);

                        const transform = outputScale !== 1
                            ? [outputScale, 0, 0, outputScale, 0, 0]
                            : null;

                        const renderContext = { canvasContext, transform, viewport: previewViewport };
                        page.render(renderContext).promise.then(() => {
                            canvas.toBlob(blob => {
                                const pageData = {
                                    pn: i + 1, viewport,
                                    preview: URL.createObjectURL(blob)
                                };
                                
                                if (opts.isFullSizePreview) {
                                    const canvasFull = document.createElement('canvas');
                                    const canvasContextFull = canvasFull.getContext('2d');

                                    canvasFull.width = Math.floor(viewport.width * outputScale);
                                    canvasFull.height = Math.floor(viewport.height * outputScale);

                                    const transform = outputScale !== 1
                                        ? [outputScale, 0, 0, outputScale, 0, 0]
                                        : null;

                                    const fullRenderContext = { canvasContext: canvasContextFull, transform, viewport };
                                    page.render(fullRenderContext).promise.then(() => {
                                        canvasFull.toBlob(blobFull => {
                                            pageData.fullPreview = URL.createObjectURL(blobFull);
                                            localStoreFile.pages.push(pageData);
                                            canvasFull.remove();
                                            canvas.remove();
                                            resolve();
                                        });
                                    });
                                } else {
                                    localStoreFile.pages.push(pageData);
                                    canvas.remove();
                                    resolve();
                                }
                            });
                        });
                    }, 100);
                });

                console.timeEnd(timerStringDebug);
            }
        });
    },

    getImagePreview(uploadedFile, store, { file, opts }) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        const localStoreFile = store.state.loadedFiles.find(lf => lf.filename === file.filename);
        localStoreFile.numPages = 1;
        localStoreFile.pages = [];

        img.onload = function () {
            const scale = .5;
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                const viewport = { width: img.width, height: img.height };
                const pageData = {
                    pn: 1, viewport, preview: URL.createObjectURL(blob)
                };

                localStoreFile.pages.push(pageData);
                canvas.remove();
            });
        }

        img.src = URL.createObjectURL(uploadedFile._file);
    },

    attachFileTypeByMime(name, file) {
        switch (file.type) {
            case 'application/zip':
                name += '.zip';
                break;

            case 'application/pdf':
                name += `.pdf`;
                break;

            default:
                name += '.pdf';
                break;
        }

        return name;
    },

    downloadBlobByFilename(file, filename) {
        downloadjs(file, filename, file.type);/* 
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();

        a.remove();
        URL.revokeObjectURL(url); */
    }
};