<template>
    <header class="header">
        <div class="header__body">
            <div class="header__nav-group">
                <div class="header__burger">
                <span></span>
                </div>
                <nav class="header__menu">
                <ul class="header__list">
                    <li>
                        <router-link :to="globalPath('')" class="header__link">
                            <img :src="globalPath('img/logo.svg')"/>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="globalPath('merge-tool')" class="header__link">
                            Merge PDF
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="globalPath('split-tool')" class="header__link">
                            Split PDF
                        </router-link>
                    </li>
                    
                    <li>
                        <router-link :to="globalPath('compression-tool')" class="header__link">
                            Compress PDF
                        </router-link>
                    </li>

                    <li>
                        <DropdownButton text="Convert PDF">
                            <template v-slot:content>
                                <li></li>
                            </template>
                        </DropdownButton>
                    </li>

                    <li>
                        <DropdownButton text="All PDF Tools">
                            <template v-slot:content>
                                <li>
                                    <router-link :to="globalPath('nup-tool')" class="header__link">
                                        N-UP PDF
                                    </router-link>
                                </li>
                                
                                <li>
                                    <router-link :to="globalPath('encrypt-tool')" class="header__link">
                                        Encrypt PDF
                                    </router-link>
                                </li>
                            </template>
                        </DropdownButton>
                    </li>
                </ul>
                </nav>
            </div>
            <div class="header__group-btn">
                <a href="#" class="btn btn--outline-default">Login</a>
                <a href="#" class="btn btn--primary">Sign Up</a>
            </div>
        </div>
    </header>
</template>

<script>
import DropdownButton from './DropdownButton.vue';

export default {
    components: { DropdownButton }
}
</script>