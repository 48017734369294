<template>
    <div class="global-loader" v-if="loader.isShow">
        <header class="header-loading">
            <div class="logo">
                <img :src="globalPath('img/logo.svg')">
            </div>
        </header>

        <div class="wrapper header-fixed">
            <main class="content">
                <div class="fullscreen-page flex flex-column items-center justify-center py-8">
                    <div class="container flex flex-column items-center">
                        <div class="loader-wrapper mb-[24px]">
                            <div class="loader">
                                <div></div><div></div><div></div>
                                <div></div><div></div><div></div>
                                <div></div><div></div>
                            </div>
                        </div>

                        <p v-if="loader.textDescription" class="typewriter font-normal">
                            {{ loader.textDescription }}
                        </p>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        loader() {
            return this.$store.state.loader;
        }
    }
}
</script>

<style>
    .global-loader {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 1000000;
        background-color: rgba(255, 255, 255, .8);
        top: 0; left: 0;
    }
</style>