<template>
    <div class="content-header-item">
        <p v-show="!isEditing" class="font-medium mr-[12px]">{{ name }}</p>
        <input ref="inputEditing" v-show="isEditing"
            @blur="isEditing = false"
            @input="$emit('inputName', $event.target.value)"
            class="input" :value="name"
            type="text" placeholder="Document name"
            style="padding: 2px 5px; width: 280px;"
        />

        <a href="#" @click="enableEditing()" v-if="!isEditing" class="btn btn-ico--default">
            <span class="ico">
                <InlineSvg :src="globalPath('img/pen-icon.svg')"/>
            </span>
        </a>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props: {
        name: {
            type: String,
            default: () => ''
        }
    },

    components: { InlineSvg },
    data: () => ({
        isEditing: false
    }),

    methods: {
        enableEditing() {
            this.isEditing = true;
            this.$nextTick(() => {
                this.$refs.inputEditing.select();
                this.$refs.inputEditing.focus();
            });
        }
    }
}
</script>