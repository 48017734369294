<template>
    <div class="dropdown" data-dropdown="dropdown" ref="dropdown">
        <a href="#" class="btn" data-role="button">
            {{ text }}
            <span class="dropdown-arrow">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_22_707)">
                        <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#211A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>

                    <defs>
                        <clipPath id="clip0_22_707">
                         <rect width="14" height="14" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </span>
        </a>

        <div class="dropdown__body" data-role="dropdown">
            <ul>
                <slot name="content"></slot>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        isOpen: false
    }),

    mounted() {
        new dropdownInit(this.$refs.dropdown, {});
    },

    props: {
        text: { type: String, default:() => '' }
    },

    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen;
        }
    }
}
</script>