<template>
    <div>
        <a href="#" class="scroll_to_top"></a>
        <div class="upload-files">
            <div class="upload-files__input hidden">
                <input type="file" :accept="acceptUpload" multiple ref="fileInput" hidden @change="uploadFiles">
            </div>

            <div class="uploader-page">
                <div class="uploader-page__body">
                    <a href="#" @click="$refs.fileInput.click()" class="btn btn--primary uploader-btn rounded-full trigger-file">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                        </svg>
                    </a>
                </div>
                
                <div class="uploader-page__drop">
                    <a href="#" @click="$refs.fileInput.click()" class="btn btn--primary uploader-btn rounded-full trigger-file">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="#fff" fill-rule="nonzero">
                            <path d="M4.8 19c-.442 0-.8-.448-.8-1s.358-1 .8-1h10.4c.442 0 .8.448.8 1s-.358 1-.8 1H4.8z"></path>
                            <path d="M7 15h6l-1 3H8z"></path>
                            <path d="M2 2v11h16V2H2zM1 0h18a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
                        </svg>
                    </a>

                    <a href="#" class="btn btn--primary uploader-btn rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                            <path fill="#FFF" d="M8.7375,5.80725 L3.021,15.70725 L0.12375,10.69725 L5.847,0.795 L8.7375,5.80725 Z M17.865,10.38225 L12.078,10.39125 L6.378,0.489 L12.1725,0.489 L17.865,10.38225 Z M17.87625,10.9875 L14.9865,15.9975 L3.5415,15.99 L6.43425,10.98375 L17.87625,10.9875 Z"></path>
                        </svg>
                    </a>
                    
                    <a href="#" class="btn btn--primary uploader-btn rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <path fill="#FFF" d="M5.3475,0.7035 L0.096,4.125 L3.708,7.03725 L9.018,3.765 L5.3475,0.7035 Z M17.904,4.14 L12.66525,0.7275 L9.01875,3.7725 L14.29875,7.03875 L17.904,4.14 Z M9.01875,10.305 L12.66525,13.35975 L17.904,9.945 L14.2995,7.0395 L9.01875,10.305 Z M0.096,9.9585 L5.3475,13.35975 L9.01875,10.305 L3.70875,7.0455 L0.096,9.9585 Z M9.01875,10.9635 L5.35575,14.0385 L3.786,13.02 L3.786,14.16 L9.01875,17.30475 L14.271,14.15175 L14.271,13.0125 L12.693,14.031 L9.01875,10.9635 Z"></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        
        <FooterPopup/>
    </div>
</template>

<script>
import FooterPopup from './FooterPopup.vue';
import uploaderMixin from '../mixins/uploaderMixin';

export default {
    mixins: [uploaderMixin],
    props: {
        acceptUpload: {
            type: String,
            default: () => '*'
        }
    },
    components: { FooterPopup }
}
</script>