<template>
    <div id="modal-1" class="modal modal-1">
        <div class="modal__dialog modal__dialog--500">
            <div class="modal__content">
                <div class="modal__header">
                    <h2 class="modal__header-title">Modal title</h2>
                    <a href="#" class="btn btn-close" data-close-modal="">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 5L25 25" stroke="#04DFD7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M25 5L5 25" stroke="#04DFD7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </a>
                </div>
                <div class="modal__body">
                    Modal Content
                </div>
                <div class="modal__footer">

                </div>
            </div>
        </div>
    </div>
</template>