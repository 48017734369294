import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import utils from './utils';

const vueApplication = createApp(App);
vueApplication.config.globalProperties.globalPath = utils.globalPath;
vueApplication.use(store).use(router).mount('#app');
vueApplication.config.globalProperties.$store = store;

window.vueApplication = vueApplication;
window.vueStore = store;
