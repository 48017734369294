<template>
    <UploaderTool :uploaderConfig="uploaderConfig" :acceptUpload="acceptUpload" v-if="!loadedFiles.length"/>

    <template v-else>
        <ChatSidebar v-if="$store.state.isEnabledChat"/>
        
        <div class="wrapper header-fixed sidebar-left-fixed sidebar-right-fixed mobile-actions-fixed" :class="{ 'full-width-hidden-chat': !$store.state.isEnabledChat }">
            <main class="content fullscreen-page">
                <div class="content-header">
                    <DocumentRename :name="documentName" @inputName="documentName = $event"/>
                    <!-- <div class="content-header-item">
                        <div class="buttons-group">
                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/minus-icon.svg')" alt="">
                            </a>
                            
                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/plus-icon.svg')" alt="">
                            </a>
                        </div>
                        <p class="text-14 font-normal">1 / 9</p>
                    </div> -->
                </div>

                <div class="content-body scroll-block">
                    <div class="container">
                        <draggable v-model="loadedFiles" item-key="filename" class="row doc-list --ordered" @start="drag = true" @end="drag = false">
                            <template #item="{element}">
                                <PreviewDocumentPage
                                    :rotateMode="false"
                                    :preview="previewDocument(element)"
                                    :name="element.name"
                                    @close="$store.dispatch('deleteFile', element.filename)"
                                />
                            </template>
                        </draggable>
                    </div>
                </div>
            </main>
            
            <MobilePageActions/>            
            <Footer :uploaderConfig="uploaderConfig" :acceptUpload="acceptUpload"/>
        </div>

        <aside class="sidebar sidebar--right" :class="{ active: isOpenedSidebar }">
            <div class="sidebar-header">
                <p class="def-text-1 font-[500]">N-Up options</p>
                <CloseMobileSidebars/>
            </div>

            <div class="sidebar-body">
                <div class="option-item">
                    <div class="option-title">Page ordering</div>
                    <ul class="option-group-list">
                        <li class="option-group__item" @click="changeOption('ordering', 'h')" :class="{ 'option--active': options.ordering === 'h' }">
                            <div class="option-group__item-title">
                                Horizontal
                            </div>
                        </li>

                        <li class="option-group__item" @click="changeOption('ordering', 'v')" :class="{ 'option--active': options.ordering === 'v' }">
                            <div class="option-group__item-title">
                                Vertical
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="option-item">
                    <div class="option-title">Select N-Up</div>
                    <ul class="option-group-list">
                        <li class="option-group__item min-w-[90px]" v-for="(mode, i) of nupModes" :key="i"
                            :class="{ 'option--active': mode.id == options.mode }" @click="changeOption('mode', mode)">
                            <div v-html="mode.icon"></div>
                            <div class="option-group__item-title">{{ mode.title }}</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="sidebar-footer pt-0">
                <ButtonProcess text="N-Up" @click="processing" :isDisabledProcess="isDisabledProcess"/>
            </div>
        </aside>
    </template>
</template>

<script>
import toolMixin from '../mixins/toolMixin';

export default {
    mixins: [toolMixin],
    data: () => ({
        downloadWindowConfig: {
            title: `N-Up PDF Done!`,
            buttonDownloadText: 'Download',
            backButtonText: 'Back to N-Up PDF'
        },

        options: {
            ordering: 'h',
            mode: 1
        },

        nupModes: [{
            title: '2-up',
            value: '2x1',
            ordering: 'h',
            icon: `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3.15" y="2.65" width="5.7" height="14.7" rx="1.35" stroke="currentColor" stroke-width="1.3"></rect>
                <rect x="12.15" y="2.65" width="5.7" height="14.7" rx="1.35" fill="currentColor" stroke="currentColor" stroke-width="1.3"></rect>
            </svg>`
        }, {
            title: '4-up',
            value: '2x2',
            ordering: 'h',
            icon: `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3.15" y="2.65" width="5.7" height="5.7" rx="1.35" stroke="currentColor" stroke-width="1.3"></rect>
                <rect x="3.15" y="11.65" width="5.7" height="5.7" rx="1.35" stroke="currentColor" stroke-width="1.3"></rect>
                <rect x="12.15" y="2.65" width="5.7" height="5.7" rx="1.35" fill="currentColor" stroke="currentColor" stroke-width="1.3"></rect>
                <rect x="12.15" y="11.65" width="5.7" height="5.7" rx="1.35" fill="currentColor" stroke="currentColor" stroke-width="1.3"></rect>
            </svg>`
        }, {
            title: '4-up vrt',
            value: '2x2',
            ordering: 'v',
            icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2.65" y="2.65" width="5.7" height="5.7" rx="1.35" stroke="currentColor" stroke-width="1.3"></rect>
                <rect x="11.65" y="2.65" width="5.7" height="5.7" rx="1.35" stroke="currentColor" stroke-width="1.3"></rect>
                <rect x="11.65" y="11.65" width="5.7" height="5.7" rx="1.35" fill="currentColor" stroke="currentColor" stroke-width="1.3"></rect>
                <rect x="2.65" y="11.65" width="5.7" height="5.7" rx="1.35" fill="currentColor" stroke="currentColor" stroke-width="1.3"></rect>
            </svg>`
        }, {
            title: '8-up',
            value: '4x2',
            ordering: 'h',
            icon: `<svg width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2.5" y="2.5" width="6" height="6" rx="1.5" stroke="currentColor"></rect>
                <rect x="10.5" y="2.5" width="6" height="6" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
                <rect x="18.5" y="2.5" width="6" height="6" rx="1.5" stroke="currentColor"></rect>
                <rect x="26.5" y="2.5" width="6" height="6" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
                <rect x="2.5" y="11.5" width="6" height="6" rx="1.5" stroke="currentColor"></rect>
                <rect x="10.5" y="11.5" width="6" height="6" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
                <rect x="18.5" y="11.5" width="6" height="6" rx="1.5" stroke="currentColor"></rect>
                <rect x="26.5" y="11.5" width="6" height="6" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
            </svg>`
        }, {
            title: '8-up vrt',
            value: '4x2',
            ordering: 'v',
            icon: `<svg width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2.5" y="2.5" width="6" height="6" rx="1.5" stroke="currentColor"></rect>
                <rect x="10.5" y="2.5" width="6" height="6" rx="1.5" stroke="currentColor"></rect>
                <rect x="18.5" y="2.5" width="6" height="6" rx="1.5" stroke="currentColor"></rect>
                <rect x="26.5" y="2.5" width="6" height="6" rx="1.5" stroke="currentColor"></rect>
                <rect x="2.5" y="11.5" width="6" height="6" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
                <rect x="10.5" y="11.5" width="6" height="6" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
                <rect x="18.5" y="11.5" width="6" height="6" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
                <rect x="26.5" y="11.5" width="6" height="6" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
            </svg>`
        }].map((item, i) => ({ ...item, id: i + 1 })),

        acceptUpload: 'application/pdf',
        documentName: 'N-Up document',
        uploaderConfig: {
            title: 'N-Up',
            description: 'N-up & PDF Imposition - Multiple pages per sheet',

            processFileOpts: {
                isSinglePreview: true
            }
        }
    }),

    components: { },
    methods: {
        changeOption(key, data) {
            if(key == 'mode') {
                this.options.ordering = data.ordering;
                this.options.mode = data.id;
            } else {
                this.options[key] = data;
            }
        },

        async processLocalyTool() {
            const processedFiles = [];
            const page_ordering = this.options.ordering === 'v' ? 'vertical' : 'horizontal';
            let pages_per_sheet = '2x2';
            const mode = this.nupModes.find(m => m.id == this.options.mode);
            if(mode) pages_per_sheet = mode.value;

            for(const lf of this.loadedFiles) {
                const file = await window.app.components.nup.apply(lf.filename, {
                    isDownload: false,
                    page_ordering, pages_per_sheet
                });

                processedFiles.push(file);
            }

            return this._fileToDownload(processedFiles);
        }
    }
}
</script>