<template>
    <div class="dropdown mx-0 max-w-full" ref="dropdown" data-insideoverflow="false" data-dropdown="dropdown">
        <div class="btn-select--drp" data-role="button">
            <select class="select">
                <option value="fit">Select Options</option>
            </select> 
        </div>

        <div class="dropdown__body" data-role="dropdown">
            <ul class="list-10">
                <li class="list-item" v-for="(item, i) of list" :key="i">
                    <label class="checkbox-custom">
                        <input type="checkbox" :value="item.value" @change="toggleChecked($event, item)" :checked="!!item.checked" hidden="">
                        <span class="checkbox-custom-icon checkbox-custom-icon--20"></span>
                        <span class="checkbox-custom-text text-[14px]">{{ item.title }}</span>
                    </label>
                </li>
            </ul>
        </div>   
    </div>
</template>

<script>
export default {
    props: {
        list: { type: Array, default: () => ([]) }    
    },

    methods: {
        toggleChecked(ev, item) {
            this.$emit('changeList', this.list.map(it => {
                if(it.value === item.value) it.checked = ev.target.checked;

                return it;
            }));
        }
    },

    mounted() {
        new dropdownInit(this.$refs.dropdown, {});
    }
}
</script>