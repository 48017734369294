<template>
    <UploaderTool :uploaderConfig="uploaderConfig" :acceptUpload="acceptUpload" v-if="!loadedFiles.length"/>

    <template v-else>
        <ChatSidebar v-if="$store.state.isEnabledChat"/>
        
        <div class="wrapper header-fixed sidebar-left-fixed sidebar-right-fixed mobile-actions-fixed" :class="{ 'full-width-hidden-chat': !$store.state.isEnabledChat }">
            <main class="content fullscreen-page">
                <div class="content-header">
                    <DocumentRename :name="documentName" @inputName="documentName = $event"/>
                    <!-- <div class="content-header-item">
                        <div class="buttons-group">
                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/minus-icon.svg')" alt="">
                            </a>
                            
                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/plus-icon.svg')" alt="">
                            </a>
                        </div>
                        <p class="text-14 font-normal">1 / 9</p>
                    </div> -->
                </div>

                <div class="content-body scroll-block">
                    <div class="container">
                        <draggable v-model="loadedFiles" item-key="filename" class="row doc-list --ordered" @start="drag = true" @end="drag = false">
                            <template #item="{element}">
                                <PreviewDocumentPage
                                    :preview="previewDocument(element)"
                                    :rotateAngle="element?.angle || 0"
                                    :name="element.name"
                                    @close="$store.dispatch('deleteFile', element.filename)"
                                    @rotate="rotateFile(element.filename)"
                                />
                            </template>
                        </draggable>
                    </div>
                </div>
            </main>
            
            <MobilePageActions/>            
            <Footer :uploaderConfig="uploaderConfig" :acceptUpload="acceptUpload"/>
        </div>

        <aside class="sidebar sidebar--right" :class="{ active: isOpenedSidebar }">
            <div class="sidebar-header">
                <p class="def-text-1 font-[500]">Compression level</p>
                <CloseMobileSidebars/>
            </div>

            <div class="sidebar-body">
                <ul class="options-list-select">
                    <li class="options-list-select__item" :class="{ 'option--active': mode.id === activeQualityMode }"
                        v-for="(mode, i) of qualityModes" :key="i" @click="activeQualityMode = mode.id">
                        <div class="options-list-select__title">
                            {{ mode.title }}
                        </div>

                        <div class="options-list-select__text">
                            {{ mode.description }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="sidebar-footer pt-0">
                <ButtonProcess text="Compress PDF" @click="processing" :isDisabledProcess="isDisabledProcess"/>
            </div>
        </aside>
    </template>
</template>

<script>
import toolMixin from '../mixins/toolMixin';

export default {
    mixins: [toolMixin],
    data: () => ({
        activeQualityMode: 1,
        qualityModes: [{
            id: 1,
            title: 'Extreme Compression',
            description: 'Less quality, high compression',
            params: {
                image_quality: 'medium',
                image_conversion: 'grayscale',
                image_resolution: 36,
                compression_speed: 'faster'
            }
        }, {
            id: 2,
            title: 'Recommended Compression',
            description: 'Good quality, good compression',
            params: {
                image_quality: 'good',
                image_conversion: 'none',
                image_resolution: 144,
                compression_speed: 'faster'
            }
        }, {
            id: 3,
            title: 'Less compression',
            description: 'High quality, less compression',
            params: {
				image_quality: 'best',
                image_conversion: 'none',
                image_resolution: 720,
                compression_speed: 'regular'
            }
        }],

        acceptUpload: 'application/pdf',
        documentName: 'Compressed',
        uploaderConfig: {
            title: 'Compress PDF Files',
            description: 'Compress PDF files',

            processFileOpts: {
                isSinglePreview: true
            }
        },

        downloadWindowConfig: {
            title: `PDF has been compressed!`,
            buttonDownloadText: 'Download compressed PDF(s)',
            backButtonText: 'Back to compress PDF'
        },
    }),

    components: { },
    methods: {
        async processLocalyTool() {
            const processedFiles = [];
            const selectedQualityMode = this.qualityModes.find(qm => qm.id === this.activeQualityMode);
            for(const lf of this.loadedFiles) {
                let _rotatedPages = this.parseRotatedPages(lf);
                const _deletedPages = [];
                
                const file = await window.app.components.compress.apply(lf.filename, {
                    isDownload: false, _rotatedPages, _deletedPages,
                    ...selectedQualityMode.params
                });

                processedFiles.push(file);
            }

            return this._fileToDownload(processedFiles);
            // const filesBeforeSizes = this.loadedFiles.map(lf => {
            //     const file = window.app.store.fs.files.find(f => f.filename === lf.filename);
            //     return file?._file?.size || 0;
            // });

            // const before = (filesBeforeSizes.reduce((res, size) => res + size.size_before, 0) / 1000).toFixed(1);
            // const after = (processedFiles.map(file => file.size).reduce((res, size) => res + size.size_after, 0) / 1000).toFixed(1);
        }
    }
}
</script>