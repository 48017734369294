<template>
    <div class="doc-item">
        <div class="doc-card" :class="{ 'cursor-move': moveMode, 'active-card-element': isActive }">
            <div class="doc-card-actions">
                <a href="#" @click="$emit('rotate')" v-if="rotateMode" class="btn p-2 btn--primary rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z" />
                    </svg>
                </a>

                <a href="#" @click="$emit('close')" class="btn p-2 btn--primary rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 384 512">
                        <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                    </svg>
                </a>
            </div>

            <div class="doc-card-preview">
                <LoaderPreview :preview="!!preview"/>                
                <img :src="preview" :style="{ transform: `rotate(${rotateAngle}deg)` }" v-if="preview">
            </div>
            
            <div class="doc-card-name" v-if="name">{{ name }}</div>
        </div>
    </div>
</template>

<script>
import LoaderPreview from './LoaderPreview.vue';
export default {
    components: { LoaderPreview },
    props: {
        moveMode: {
            type: Boolean,
            default: () => true    
        },
        
        isActive: {
            type: Boolean,
            default: () => false    
        },
        
        rotateMode: {
            type: Boolean,
            default: () => true    
        },

        preview: {
            type: [String, Boolean],
            default: () => false
        },
        
        rotateAngle: {
            type: [Number, Boolean],
            default: () => false
        },

        name: {
            type: String,
            default: () => ''
        }
    }
}
</script>

<style scoped>
    .doc-card-name {
        background: transparent;
    }

    .active-card-element {
        background: #F1EDFC; 
    }
</style>