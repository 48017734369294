<template>
    <div class="wrapper header-fixed download-page-fixed">
        <main class="content flex">
            <div class="download-page">
                <a href="#" @click="closeWindow" class="btn w-full page-back-btn">
                    <span class="btn-icon mr-4">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_26_2027)">
                                <path d="M15.1875 9H2.8125" stroke="#F21B3F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.875 3.9375L2.8125 9L7.875 14.0625" stroke="#F21B3F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_26_2027">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    
                    <span class="color-primary font-medium">
                        {{ downloadWindow.backButtonText || 'Back to tool' }}
                    </span>
                </a>

                <div class="download-page__body">
                    <div class="container">
                        <div class="download-page__content">
                            <h1 class="mb-[50px]">
                                {{ downloadWindow.title || 'Processing completed' }}
                            </h1>

                            <a href="#" @click="downloadFile" class="btn btn--primary download-btn radius-5 mb-[20px]">
                                <span class="btn-icon mr-4">
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_25_1935)">
                                            <path d="M12.5 3V17.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.75 10.5L12.5 17.25L19.25 10.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4.25 20.25H20.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>

                                        <defs>
                                            <clipPath id="clip0_25_1935">
                                                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>

                                <span>
                                    {{ downloadWindow.buttonDownloadText || 'Download result' }}
                                </span>
                            </a>

                            <div class="buttons-group">
                                <a href="#" class="btn btn--outline-default btn-square--xxl rounded-full">
                                    <img :src="globalPath('img/google-disk-icon.svg')" alt="">
                                </a>

                                <a href="#" class="btn btn--outline-default btn-square--xxl rounded-full">
                                    <img :src="globalPath('img/dropbox-icon.svg')" alt="">
                                </a>
                                
                                <a href="#" class="btn btn--outline-default btn-square--xxl rounded-full">
                                    <img :src="globalPath('img/link-icon.svg')" alt="">
                                </a>

                                <a href="#" class="btn btn--outline-default btn-square--xxl rounded-full">
                                    <img :src="globalPath('img/ico-trash.svg')" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="download-page__footer">
                    <div class="container">
                        <div class="download-page__content">
                            <h4 class="mb-[20px]">How can you thank us? Spread the word!</h4>
                            <p class="mb-[28px]">Please share the tool to inspire more productive people!</p>
                            <div class="buttons-group">
                                <a href="#" class="btn btn--outline-default btn-square--xl rounded-full">
                                    <img :src="globalPath('img/facebook-icon.svg')" alt="">
                                </a>
                                
                                <a href="#" class="btn btn--outline-default btn-square--xl rounded-full">
                                    <img :src="globalPath('img/x-icon.svg')" alt="">
                                </a>

                                <a href="#" class="btn btn--outline-default btn-square--xl rounded-full">
                                    <img :src="globalPath('img/linkedin-icon.svg')" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import utils from '../utils';

export default {
    computed: {
        downloadWindow() {
            return this.$store.state.downloadWindow;
        }
    },

    methods: {
        closeWindow() {
            this.$store.commit('updateDownloadWindow', { isShow: false });
        },

        downloadFile() {
            const filename = utils.attachFileTypeByMime(`${this.downloadWindow.documentName}`, this.downloadWindow.file);
            utils.downloadBlobByFilename(this.downloadWindow.file, filename);
        }
    }
}
</script>

<style>
    .download-page-fixed {
        position: fixed;
        z-index: 49;
        left: 0;
        top: 0;
        background: #fff;
    }
</style>