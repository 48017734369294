<template>
    <UploaderTool :uploaderConfig="uploaderConfig" :acceptUpload="acceptUpload" v-if="!loadedFiles.length"/>

    <template v-else>
        <ChatSidebar v-if="$store.state.isEnabledChat"/>
        
        <div class="wrapper header-fixed sidebar-left-fixed sidebar-right-fixed mobile-actions-fixed" :class="{ 'full-width-hidden-chat': !$store.state.isEnabledChat }">
            <main class="content fullscreen-page">
                <div class="content-header">
                    <DocumentRename :name="documentName" @inputName="documentName = $event"/>
                    <!-- <div class="content-header-item">
                        <div class="buttons-group">
                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/minus-icon.svg')" alt="">
                            </a>
                            
                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/plus-icon.svg')" alt="">
                            </a>
                        </div>
                        <p class="text-14 font-normal">1 / 9</p>
                    </div> -->
                </div>

                <div class="content-body scroll-block">
                    <div class="container">
                        <draggable v-model="loadedFiles" item-key="filename" class="row doc-list --ordered" @start="drag = true" @end="drag = false">
                            <template #item="{element}">
                                <PreviewDocumentPage
                                    :rotateMode="false"
                                    :preview="previewDocument(element)"
                                    :name="element.name"
                                    @close="$store.dispatch('deleteFile', element.filename)"
                                />
                            </template>
                        </draggable>
                    </div>
                </div>
            </main>
            
            <MobilePageActions/>            
            <Footer :uploaderConfig="uploaderConfig" :acceptUpload="acceptUpload"/>
        </div>

        <aside class="sidebar sidebar--right" :class="{ active: isOpenedSidebar }">
            <div class="sidebar-header">
                <p class="def-text-1 font-[500]">N-Up options</p>
                <CloseMobileSidebars/>
            </div>

            <div class="sidebar-body">
                <div class="option-item">
                    <div class="option-title">Password</div>
                    <input type="text" v-model="options.password" class="input" placeholder="Example: open123">
                </div>

                <div class="option-item" v-if="isShowPermissionPassword">
                    <div class="option-title">Permission password</div>
                    <input type="text" v-model="options.permissionPassword" class="input" placeholder="Example: edit123">
                </div>

                <div class="option-item">
                    <div class="option-title">Options</div>

                    <MultyOptions :list="options.encryptOptions" @changeList="selectEncryptOptions"/>
                </div>

                <div class="option-item">
                    <div class="option-title">Encryption</div>
                    <ul class="option-group-list">
                        <li class="option-group__item" v-for="(opt, i) of encryptionOptions" :key="i"
                            :class="{ 'option--active': options.encrypt === opt.value }"
                            @click="options.encrypt = opt.value">
                            <div class="option-group__item-title">{{ opt.title }}</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="sidebar-footer pt-0">
                <ButtonProcess text="Protect" @click="processing" :isDisabledProcess="isDisabledProcess"/>
            </div>
        </aside>
    </template>
</template>

<script>
import toolMixin from '../mixins/toolMixin';
import MultyOptions from '../components/MultyOptions.vue';

export default {
    mixins: [toolMixin],
    data: () => ({
        downloadWindowConfig: {
            title: `Document(s) encrypted`,
            buttonDownloadText: 'Download',
            backButtonText: 'Back to Encrypt PDF'
        },
        
        encryptionOptions: [
            { title: 'Medium: (RC4v2 128 bits)', value: 'rc4v2' },
            { title: 'Medium: (AES 128 bits)', value: 'encrypt_aesv2' },
            { title: 'Strong: (AES 256 bits)', value: 'encrypt_aesv3' },
        ],

        options: {
            password: '',
            permissionPassword: '',
            encrypt: 'rc4v2',
            encryptOptions: [
                { value: 'edit', title: 'Modifying' },
                { value: 'copy', title: 'Copying text and graphics' },
                { value: 'fillandsign', title: 'Filling forms' },
                { value: 'editnotes', title: 'Commenting' },
                { value: 'assemble', title: 'Insert, rotate, or delete pages' },
            ]
        },

        acceptUpload: 'application/pdf',
        documentName: 'Encrypt document',
        uploaderConfig: {
            title: 'Encrypt and Protect PDF online',
            description: 'Protect PDF file with password and permissions. Add restrictions to your PDF file',

            processFileOpts: {
                isSinglePreview: true
            }
        }
    }),

    computed: {
        isShowPermissionPassword() {
            return this.options.encryptOptions.find(eo => !!eo.checked);
        }    
    },

    components: { MultyOptions },
    methods: {
        selectEncryptOptions(list) {
            this.options.encryptOptions = list;
        },

        async processLocalyTool() {
            const processedFiles = [];
            
            const password_open = this.options.password;
            const password_own = this.options.permissionPassword;
            const encrypt = this.options.encrypt;
            const allow = this.options.encryptOptions.filter(eo => !!eo.checked).map(eo => eo.value);

            for(const lf of this.loadedFiles) {
                const file = await window.app.components.protect.apply(lf.filename, {
                    isDownload: false, password_open, password_own, encrypt, allow
                });

                processedFiles.push(file);
            }

            return this._fileToDownload(processedFiles);
        }
    }
}
</script>