<template>
    <aside class="sidebar sidebar--left" :class="{ active: isOpenedSidebar }">
        <div class="sidebar-header">
            <p class="def-text-1 font-[500]">Chat</p>
            <div class="dbl-btns dbl-btns--14">
                <a href="#" class="btn btn-ico--default">
                    <span class="ico ico-20">
                        <InlineSvg :src="globalPath('img/ico-export.svg')"/>
                    </span>
                </a>
                <a href="#" class="btn btn-ico--default">
                    <span class="ico ico-20">
                        <InlineSvg :src="globalPath('img/ico-clockwise.svg')"/>
                    </span>
                </a>
                <a href="#" class="btn btn-ico--default">
                    <span class="ico ico-20">
                        <InlineSvg :src="globalPath('img/ico-trash.svg')"/>
                    </span>
                </a>
                
                <CloseMobileSidebars/>
            </div>
        </div>

        <div class="sidebar-body">
            <ul class="list-comments">
                <li class="comment-item">
                    <div class="comment">
                        <div class="user-img user-img--26">
                            <img :src="globalPath('img/user-ico.png')">
                        </div>
                        
                        <div class="comment-content">
                            <div class="row-options mb-[-10px]">
                                <div class="option space-text mb-[10px]">
                                    <p class="def-text-1 font-[500]">Bot Name</p>
                                </div>
                                <div class="option whitespace-nowrap mb-[10px]">
                                    <p class="def-text-2 def-color-05">12:30 am</p>
                                </div>
                            </div>
                            <div class="list-user-comment">
                                <div class="comment-box">
                                    <p>
                                        Welcome to this helpful PDF file that provides a framework 
                                        for onboarding agencies through the Government Enterprise 
                                        Agreement. This document covers all agencies currently 
                                        part of the agreement and outlines the three types of 
                                        tenants required based on utilization and associated thresholds.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="sidebar-footer pt-0">
            <form class="send-form h-[52px]" method="post">
                <input type="text" class="input text-[15px]" placeholder="Ask any question...">
                <button type="submit" class="btn send-form--btn">
                    <span class="ico ico-20">
                        <img :src="globalPath('img/ico-send.svg')">
                    </span>
                </button>
            </form>
        </div>
    </aside>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import CloseMobileSidebars from '../components/CloseMobileSidebars.vue';

export default {
    components: { InlineSvg, CloseMobileSidebars },

    computed: {
        isOpenedSidebar: {
            get() { return this.$store.state.sidebarsMobile.left; },
            set(val) { this.$store.state.sidebarsMobile.left = val; }
        }
    }
}
</script>