<template>
    <div class="loading-preview" v-if="!preview">
        <div class="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        preview: {
            type: Boolean,
            default: () => false
        }
    }
}
</script>

<style scoped>
    .loading-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
</style>