<template>
    <div class="container editor-container">
        <div v-if="!imageConfig.isLoaded" class="loading-image-split">
            <LoaderPreview :preview="imageConfig.isLoaded"/>
        </div>

        <div class="widget-page-editor">
            <div class="widget-doc widget--add widget--active" style="top: 0;left: 0;">
                <div class="widget-redactor">
                    <div style="width: 100%;height: 100%;">                        
                        <img :src="previewSplitPage" ref="boundRect" :style="imageStyles" @loadstart="imageConfig.isLoaded = false" @load="loadImage">
                        <div class="resize-split" v-if="imageConfig.isLoaded">
                            <VueDraggableResizable :handles="crop.handles" :x="crop.left" :key="tick"
                                :y="crop.top" :w="crop.width" :h="crop.height" @resizeStop="(left, top, width, height) => onResizstop(left, top, width, height)"
                                :draggable="false" :parent="true" :active="crop.active" @deactivated="deactivated">
                                <div class="rect-split"></div>
                            </VueDraggableResizable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import _ from 'lodash';
import LoaderPreview from './LoaderPreview.vue';
window._ = _;

export default {
    components: { VueDraggableResizable, LoaderPreview },
    props: {
        settings: {
            type: Object,
            default: () => ({})
        },

        previewSplitPage: {
            type: [String, Boolean],
            default: () => (false)
        }
    },

    mounted() {
        this.calculateInitResize();
    },

    data() {
        return {
            imageConfig: {
                width: 100,
                height: 100,
                isLoaded: false
            },

            crop: {
                handles: ['tm','bm','ml','mr'],
                width: 100,
                height: 100,
                left: 0,
                top: 0,
                active: true
            },

            tick: false
        }
    },

    computed: {
        imageStyles() {
            const styles = {
                width: '100%',
                height: '100%',
                position: 'relative',
            };

            if(this.imageConfig?.isLoaded) return styles;
            
            return {
                ...styles, position: 'absolute',
                left: 0, top: 0,
                opacity: 0
            };
        }
    },

    watch: {
        settings() {
            this.calculateInitResize();
        },

        previewSplitPage() {
            this.calculateInitResize();
        }
    },

    methods: {
        onResizstop(left, top, width, height) {
            this.crop.width = width;
            this.crop.height = height;
            this.crop.left = left;
            this.crop.top = top;
            
            this.emitUpdateSettings();
        },

        emitUpdateSettings() {
            const cropSettings = _.cloneDeep({
                width: this.crop.width / this.imageConfig.width,
                height: this.crop.height / this.imageConfig.height,
                left: this.crop.left / this.imageConfig.width,
                top: this.crop.top / this.imageConfig.height,
                handles: this.crop.handles
            });

            this.$emit('updateSettings', cropSettings);
        },

        deactivated() {
            this.crop.active = false;
            this.$nextTick(() => {
                this.crop.active = true;
            });
        },

        calculateInitResize() {
            if(!this.imageConfig?.isLoaded) return false;

            if(this.settings.cropSettings) {
                const { left, top, width, height, handles } = _.cloneDeep(this.settings.cropSettings);

                this.crop = {
                    ...this.crop,
                    width: this.imageConfig.width * width,
                    height: this.imageConfig.height * height,
                    top: this.imageConfig.height * top,
                    left: this.imageConfig.width * left,
                    handles
                };
            } else {
                switch(this.settings.splitAs) {
                    case 'v': {
                        if(this.settings.leftToRight) {
                            this.crop = {
                                ...this.crop,
                                width: this.imageConfig.width / 2,
                                height: this.imageConfig.height,
                                handles: ['ml'],
                                top: 0, left: this.imageConfig.width / 2
                            };
                        } else {
                            this.crop = {
                                ...this.crop,
                                left: 0, top: 0,
                                width: this.imageConfig.width / 2,
                                height: this.imageConfig.height,
                                handles: ['mr']
                            };
                        }
                        
                        break;
                    }

                    case 'h': {
                        this.crop = {
                            ...this.crop,
                            width: this.imageConfig.width,
                            height: this.imageConfig.height / 2,
                            handles: ['bm'],
                            top: 0, left: 0
                        };

                        break;
                    }
                }

                this.emitUpdateSettings();
            }

            this.tick = !this.tick;
        },

        async loadImage() {
            this.imageConfig.isLoaded = true;

            this.$nextTick(() => this.calculateImageSize());
        },

        calculateImageSize() {
            const bounds = this.$refs.boundRect.getBoundingClientRect();
            this.imageConfig.width = bounds.width;
            this.imageConfig.height = bounds.height;
            
            this.calculateInitResize();
        }
    }
}
</script>

<style scoped>
    .resize-split {
        width: 100%;
        height: 100%;
        border: 1px solid var(--dark);
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .rect-split {
        background: rgba(0, 0, 0, .2);
        outline: 1px dashed var(--dark);
        width: 100%;
        height: 100%
    }

    .loading-image-split {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
<style>
    .resize-split .handle {
        min-width: 16px;
        min-height: 16px;
        background-color: var(--dark);
        border: 1px solid var(--white);
        border-radius: 5px;
    }

    .vdr{touch-action:none;position:absolute;box-sizing:border-box;border:1px dashed black}.handle{box-sizing:border-box;position:absolute;width:10px;height:10px;background:#EEE;border:1px solid #333}.handle-tl{top:-10px;left:-10px;cursor:nw-resize}.handle-tm{top:-10px;left:50%;margin-left:-5px;cursor:n-resize}.handle-tr{top:-10px;right:-10px;cursor:ne-resize}.handle-ml{top:50%;margin-top:-5px;left:-10px;cursor:w-resize}.handle-mr{top:50%;margin-top:-5px;right:-10px;cursor:e-resize}.handle-bl{bottom:-10px;left:-10px;cursor:sw-resize}.handle-bm{bottom:-10px;left:50%;margin-left:-5px;cursor:s-resize}.handle-br{bottom:-10px;right:-10px;cursor:se-resize}@media only screen and (max-width: 768px){[class*=handle-]:before{content:"";left:-10px;right:-10px;bottom:-10px;top:-10px;position:absolute}}
</style>