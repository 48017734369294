<template>
    <Header :class="{ 'disabled-loader': loader.isShow }"/>
    <router-view></router-view>

    <GlobalLoader/>
    <DownloadWindow v-if="downloadWindow.isShow"/>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import GlobalLoader from './components/GlobalLoader.vue';
import DownloadWindow from './components/DownloadWindow.vue';

export default {
    components: { Header, Footer, GlobalLoader, DownloadWindow },
    mounted() {
        $('body').on('click', e => {
            if(!$(e.target).closest('.doc-list-aside').length) {
                this.$store.commit('close-aside');
            }
        });
    },

    computed: {
        loader() {
            return this.$store.state.loader;
        },

        downloadWindow() {
            return this.$store.state.downloadWindow;
        }
    }
}
</script>

<style>
    .disabled-loader, .disabled-loader * {
        pointer-events: none;
    }

    .disabled-loader a, .disabled-loader button {
        opacity: .65;
    }

    .full-width-hidden-chat {
        padding-left: 0px !important;
    }

    @media (min-width: 1199.98px) {
        .full-width-hidden-chat-aside {
            left: 0px !important;
        }
    }
</style>