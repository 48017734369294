import { createStore } from 'vuex';
import utils from '../utils';

export default createStore({
    state: {
        isEnabledChat: false,
        downloadWindow: {
            isShow: false,
            file: false,
            documentName: false,
            title: 'PDF has been split!',
            buttonDownloadText: 'Download Split PDF',
            backButtonText: 'Back to Split PDF'
        },

        sidebarsMobile: {
            left: false,
            right: false
        },

        loadedFiles: [],
        loader: {
            textDescription: 'Splitting PDF file...',
            isShow: false
        }
    },

    mutations: {
        'toggle-aside'() {
            const cls = 'aside-open';
            if(document.body.classList.contains(cls)) {
                document.body.classList.remove('aside-open');
            } else {
                document.body.classList.add('aside-open');
            }
        },

        'close-aside'() {
            document.body.classList.remove('aside-open');
        },

        updateLoadedFilesList(state, data = []) {
            state.loadedFiles = data;
        },

        updateDownloadWindow(state, data) {
            state.downloadWindow = { ...state.downloadWindow, ...data };
        },

        clearFiles(state) {
            for(const file of state.loadedFiles) {
                window.app.store.fs.unlinkFile(file.filename).catch(err => {
                    console.error(err);
                });
            }

            state.loadedFiles = [];
        }
    },

    actions: {
        loaderSetup(store, data = {}) {
            store.state.loader = { ...store.state.loader, ...data };
        },

        generatePreviewsFile(store, { file, opts }) {
            if (!file) return false;

            const uploadedFile = window.app.store.fs.files.find(f => f.filename === file.filename);
            if (uploadedFile && uploadedFile._file) {
                if (/^(image)/g.test(uploadedFile._file.type)) {
                    utils.getImagePreview(uploadedFile, store, { file, opts });
                } else {
                    utils.generatePreviewsOfPDF(uploadedFile, store, { file, opts });
                }
            }
        },

        async uploadFiles(store, { files, opts }) {
            for (const file of files) {
                store.state.loadedFiles.push({ filename: file.name, isTemp: true });
            }

            const uploadedFiles = await window.app.store.fs.uploadFiles(files || []);
            store.state.loadedFiles = store.state.loadedFiles.filter(lf => !lf.isTemp);

            for (const file of uploadedFiles) {
                store.state.loadedFiles.push({
                    filename: file.filename,
                    name: file.name,
                    hash: file.hash,
                    angle: 0
                });

                store.dispatch('generatePreviewsFile', { file, opts });
            }
        },

        async deleteFile(store, filename) {
            store.state.loadedFiles = store.state.loadedFiles.filter(lf => lf.filename !== filename);
        }
    }
})