<template>
    <div class="wrapper header-fixed">
        <main class="content start-page">
            <div class="container">
                <div class="start-page__content">
                    <h1 class="mb-[30px]" v-if="config.title">{{ config.title }}</h1>
                    <p class="subtiltle-text mb-[50px]" v-if="config.description">
                        {{ config.description }}
                    </p>
                    
                    <div class="buttons-group w-full">
                        <label class="upload-file flex-auto">
                            <input type="file" :accept="acceptUpload" hidden @change="uploadFiles" multiple>
                            <div class="btn btn--primary upload-file__btn radius-5 w-full">Select PDF File</div>
                        </label>
                        
                        <a href="#" class="btn btn--outline-default btn-square--xxl rounded-full">
                            <img :src="globalPath('img/google-disk-icon.svg')" alt="">
                        </a>
                        
                        <a href="#" class="btn btn--outline-default btn-square--xxl rounded-full">
                            <img :src="globalPath('img/dropbox-icon.svg')" alt="">
                        </a>                    
                    </div>
                    
                    <p>or drop PDF here</p>
                </div>
            </div>
        </main>
        
        <Footer :uploaderConfig="uploaderConfig"/>
    </div>
</template>

<script>

import Footer from './Footer.vue';
import uploaderMixin from '../mixins/uploaderMixin';

export default {
    mixins: [uploaderMixin],
    props: {
        acceptUpload: {
            type: String,
            default: () => '*'
        }
    },

    components: { Footer },
}
</script>