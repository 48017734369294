<template>
    <a href="#" class="btn btn--primary btn--lg rounded-[4px] w-full text-center" :class="{ disabled: isDisabledProcess }">
        <span class="font-[500]">{{ text }}</span>
        <span class="ico ml-[10px] ico-20">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11_300)">
                    <path d="M10.625 2.5L18.125 10L10.625 17.5V13.75H5.625V6.25H10.625V2.5Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M3.125 13.75V6.25" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>

                <defs>
                    <clipPath id="clip0_11_300">
                        <rect width="20" height="20" fill="currentcolor"></rect>
                    </clipPath>
                </defs>
            </svg>
        </span>
    </a>
</template>

<script>
export default {
    props: {
        isDisabledProcess: { type: Boolean, default: () => false },
        text: { type: String, default: () => '' }
    }
}
</script>