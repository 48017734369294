export default {
    props: {
        uploaderConfig: {
            type: [Boolean, Object],
            default: () => false
        }
    },
    
    methods: {
        async uploadFiles(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            
            const files = ev.target.files || [];
            await this.$store.dispatch('uploadFiles', {
                files, opts: this?.config?.processFileOpts || {}
            });
            
            ev.target.value = '';
        }
    },

    computed: {
        config() {
            const uploaderConfig = this.uploaderConfig || {};

            return {
                title: uploaderConfig?.title,
                description: uploaderConfig?.description,
                processFileOpts: uploaderConfig?.processFileOpts || {}
            };
        }
    }
};