<template>
    <UploaderTool :uploaderConfig="uploaderConfig" v-if="!loadedFiles.length" :acceptUpload="acceptUpload"/>

    <template v-else>
        <ChatSidebar v-if="$store.state.isEnabledChat"/>
        <div class="wrapper header-fixed sidebar-left-fixed sidebar-right-fixed mobile-actions-fixed" :class="{ 'full-width-hidden-chat': !$store.state.isEnabledChat }">
            <div class="doc-list-aside" :class="{ 'full-width-hidden-chat-aside': !$store.state.isEnabledChat }">
                <div class="scroll-block">
                    <div class="row doc-list --ordered">
                        <PreviewDocumentPage v-for="(element, i) of loadedFiles" :key="i"
                            :preview="previewDocument(element)"
                            :rotateAngle="element?.angle || 0"
                            :name="element.name"
                            @close="$store.dispatch('deleteFile', element.filename)"
                            @rotate="rotateFile(element.filename)"
                            :moveMode="false"
                            :rotateMode="false"
                            :isActive="element.filename == activeDocument"
                            @click="changeActiveDocument(element.filename)"
                        />
                    </div>
                </div>

                <a href="#" class="btn toggle-handler" @click="$store.commit('toggle-aside')">
                    <span class="closed-state">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_25_1615)">
                                <path d="M15.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V6.75C4.5 6.55109 4.57902 6.36032 4.71967 6.21967C4.86032 6.07902 5.05109 6 5.25 6H12.75L16.5 9.75V20.25C16.5 20.4489 16.421 20.6397 16.2803 20.7803C16.1397 20.921 15.9489 21 15.75 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.5 6V3.75C7.5 3.55109 7.57902 3.36032 7.71967 3.21967C7.86032 3.07902 8.05109 3 8.25 3H15.75L19.5 6.75V17.25C19.5 17.4489 19.421 17.6397 19.2803 17.7803C19.1397 17.921 18.9489 18 18.75 18H16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.25 14.25H12.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.25 17.25H12.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                        </svg>
                    </span>
                    <span class="shown-state">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                        </svg>
                    </span>
                </a>
            </div>

            <main class="content fullscreen-page">
                <div class="content-header">
                    <DocumentRename :name="documentName" @inputName="documentName = $event"/>

                    <!-- <div class="content-header-item">
                        <div class="buttons-group">
                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/minus-icon.svg')" alt="">
                            </a>

                            <a href="#" class="btn btn--outline-default btn-square--sm rounded-full">
                                <img :src="globalPath('img/plus-icon.svg')" alt="">
                            </a>
                        </div>

                        <p class="text-14 font-normal">1 / 9</p>
                    </div> -->
                </div>
                
                <div class="content-body scroll-block">
                    <SplitPreview :settings="activeSettings"
                        :previewSplitPage="previewSplitPage"
                        @updateSettings="updateSplitSettings"
                    />
                </div>
            </main>
            
            <MobilePageActions/>            
            <Footer :uploaderConfig="uploaderConfig" :acceptUpload="acceptUpload"/>
        </div>

        <aside class="sidebar sidebar--right" :class="{ active: isOpenedSidebar }">
            <div class="sidebar-header">
                <p class="def-text-1 font-[500]">Split in Half</p>
                <CloseMobileSidebars/>
            </div>

            <div class="sidebar-body">
                <div class="option-item">
                    <div class="option-title">Options</div>
                    <ul class="option-group-list">
                        <li class="option-group__item" @click="changeSettingsValue('splitAs', 'v')" :class="{ 'option--active': activeSettings.splitAs === 'v' }">
                            <div class="option-group__item-title">
                                Split Vertically
                            </div>
                        </li>

                        <li class="option-group__item" @click="changeSettingsValue('splitAs', 'h')" :class="{ 'option--active': activeSettings.splitAs === 'h' }">
                            <div class="option-group__item-title">
                                Split Horizontally
                            </div>
                        </li>
                        
                        <li class="option-group__item" @click="changeSettingsValue('leftToRight', !activeSettings.leftToRight)" :class="{ 'option--active': activeSettings.leftToRight }">
                            <div class="option-group__item-title">
                                Left to Right
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="option-item mt-4">
                    <div class="form-group">
                        <label class="form-group-title">Exclude Pages:</label>
                        <input type="text" :value="excludeCurrentPagesString" @change="changeInputExcludePages" class="input" placeholder="Example: 1,2,3">
                    </div>
                </div>
            </div>

            <div class="sidebar-footer pt-0">
                <ButtonProcess text="Split PDF" @click="processing" :isDisabledProcess="isDisabledProcess"/>
            </div>
        </aside>
    </template>
</template>

<script>
import toolMixin from '../mixins/toolMixin';
import _ from 'lodash';
import SplitPreview from '../components/SplitPreview.vue';

const defaultSetting = {
    splitAs: 'v', // h,
    leftToRight: false,
    excludePages: [],
    cropSettings: {
        handles: ['mr'],
        height: 1,
        left: 0,
        top: 0,
        width: .5
    }
};

export default {
    components: { SplitPreview },
    mixins: [toolMixin],
    data: () => ({
        acceptUpload: 'application/pdf',
        activeDocument: '',
        activeSettings: _.cloneDeep(defaultSetting),
        settingsOfDocuments: [],
        documentName: 'Split Document',
        uploaderConfig: {
            title: 'Split PDF File',
            description: 'Split PDF file',

            processFileOpts: {
                isSinglePreview: true,
                isFullSizePreview: true
            }
        },

        downloadWindowConfig: {
            title: 'PDF document(s) has been splitted!',
            buttonDownloadText: 'Download',
            backButtonText: 'Back to Split PDF'
        },
    }),

    created() {
        window.qq = this;
    },

    watch: {
        loadedFiles() {
            if(!this.loadedFiles.length) return this.setActiveDocument('');

            if(!this.activeDocument) {
                this.setActiveDocument(this.loadedFiles[0]?.filename);
            } else {
                const fl = this.loadedFiles.find(l => l.filename === this.activeDocument);
                if(!fl) return this.setActiveDocument(this.loadedFiles[0]?.filename);
            }
        }
    },

    computed: {
        previewSplitPage() {
            try {
                const file = this.loadedFiles.find(lf => lf.filename === this.activeDocument);
                if(!file) return false;

                return (file?.pages || [])[0]?.fullPreview;
            } catch(err) {
                console.error(err);
                return false;
            }
        },
        
        excludeCurrentPagesString() {
            let result = '';
            const excludePages = this.activeSettings.excludePages || [];

            for (let i = 0; i < excludePages.length; i++) {
                const start = excludePages[i];
                let end = start;

                while (excludePages[i + 1] - excludePages[i] === 1) {
                    end = excludePages[i + 1];
                    i++;
                }
                result += (start === end) ? `${start}, ` : `${start}-${end}, `;
            }

            return result.slice(0, -2);
        }
    },

    methods: {
        changeInputExcludePages(ev) {
            const text = ev.target.value || '';
            const parts = text.split(',').map(part => part.trim());

            let allPages = [];
            parts.forEach(part => {
                if (part.includes('-')) {
                    const [start, end] = part.split('-').map(Number);
                    for (let i = start; i <= end; i++) {
                        allPages.push(i);
                    }
                } else {
                    allPages.push(Number(part));
                }
            });
            
            const document = this.loadedFiles.find(lf => this.activeDocument == lf.filename);
            if(!document) {
                allPages = [];
            } else {
                allPages = [...new Set(allPages)].sort((a, b) => a - b).filter(num => {
                    if(num <= 0 || num > document.numPages) return false;
                    
                    return true;
                });
            }

            this.changeSettingsValue('excludePages', allPages);
        },

        updateSplitSettings(cropSettings) {
            this.activeSettings.cropSettings = cropSettings;
            this.syncCurrentSettings();
        },

        changeSettingsValue(key, value) {
            if(['splitAs', 'leftToRight'].includes(key)) {
                this.activeSettings.cropSettings = false;
            }
            
            this.activeSettings = { ...this.activeSettings, [key]: value };
            this.syncCurrentSettings();
        },

        syncCurrentSettings() {
            const setting = this.settingsOfDocuments.find(sod => sod.filename === this.activeDocument);

            if(!setting) {
                this.settingsOfDocuments.push({
                    filename: this.activeDocument,
                    settings: this.activeSettings
                });
            } else {
                setting.settings = this.activeSettings;
            }
        },

        changeActiveDocument(filename) {
            if(this.activeDocument === filename) return false;
            const setting = this.settingsOfDocuments.find(sod => sod.filename === filename);
            if(setting) {
                this.activeSettings = _.cloneDeep(setting?.settings || defaultSettings);
            } else {
                this.activeSettings = _.cloneDeep({
                    ...this.activeSettings,
                    excludePages: []
                });
            }

            this.setActiveDocument(filename);
        },

        setActiveDocument(filename) {
            this.activeDocument = filename;
        },

        async processLocalyTool() {
            const processedFiles = [];
            for(const file of this.loadedFiles) {
                try {
                    let documentSetting = this.settingsOfDocuments.find(sd => sd.filename === file.filename);
                    if(!documentSetting) {
                        documentSetting = _.cloneDeep(defaultSetting);
                    } else {
                        documentSetting = _.cloneDeep(documentSetting.settings);
                    }

                    let params = { _rotatedPages: {}, _deletedPages: [] };
                    
                    const width = file.pages[0].viewport.width;
                    const height = file.pages[0].viewport.height;

                    params.doc_width = width / 72;
                    params.doc_height = height / 72;
                    params.width = (width * documentSetting.cropSettings.width) / 0.75;
                    params.height = (height * documentSetting.cropSettings.height) / 0.75;

                    params.split = documentSetting.splitAs === 'v' ? 'vertically' : 'horizontally'
                    params.arabic = documentSetting.leftToRight;
                    params.pattern = documentSetting.excludePages.join(',');

                    const fileProcessed = await window.app.components.splitHalf.apply(file.filename, {
                        isDownload: false, ...params
                    });

                    processedFiles.push(fileProcessed);
                } catch(err) {
                    console.error(err);
                }
            }
            
            return this._fileToDownload(processedFiles);
        }
    }
}
</script>