import draggable from 'vuedraggable';
import DocumentRename from '../components/DocumentRename.vue';
import CloseMobileSidebars from '../components/CloseMobileSidebars.vue';
import MobilePageActions from '../components/MobilePageActions.vue';
import InlineSvg from 'vue-inline-svg';
import ChatSidebar from '../components/ChatSidebar.vue';
import PreviewDocumentPage from '../components/PreviewDocumentPage.vue';
import Footer from '../components/Footer.vue';
import UploaderTool from '../components/UploaderTool.vue';
import ButtonProcess from '../components/ButtonProcess.vue';

export default {
    components: {
        draggable, DocumentRename, InlineSvg,
        CloseMobileSidebars, MobilePageActions,
        ChatSidebar, PreviewDocumentPage, Footer,
        UploaderTool, ButtonProcess    
    },

    mounted() {
        this.$store.commit('clearFiles');
        this.closeDownloadWindow();
    },

    data: () => ({
        acceptUpload: 'image/png,image/jpeg,application/pdf',
        documentName: 'Processed document',
        drag: false,
        uploaderConfig: {
            title: 'Merge PDF Files',
            description: 'Combine multiple PDF files into one PDF',

            processFileOpts: {
                isSinglePreview: true
            }
        },

        downloadWindowConfig: {
            documentName: 'Document',
            title: `N-Up PDF Done!`,
            buttonDownloadText: 'Download',
            backButtonText: 'Back to N-Up PDF'
        },

        toolName: 'any'
    }),

    computed: {
        isDisabledProcess() {
            switch(this.toolName) {
                case 'merge': return this.loadedFiles.length < 2; 
            }

            return false;
        },

        processFiles() {
            return this.loadedFiles.map(lf => lf.filename);
        },

        loadedFiles: {
            get() { return this.$store.state.loadedFiles; },
            set(value) {
                this.$store.commit('updateLoadedFilesList', value);
            }
        },

        isOpenedSidebar: {
            get() { return this.$store.state.sidebarsMobile.right; },
            set(val) { this.$store.state.sidebarsMobile.right = val; }
        }
    },

    methods: {
        async _fileToDownload(processedFiles) {
            let file = processedFiles.at(-1);
            if(processedFiles.length > 1) {
                file = await window.app.store.fs.filesToZip(processedFiles);
            }

            return file;
        },

        closeDownloadWindow() {
            this.$store.commit('updateDownloadWindow', { isShow: false });
        },

        previewDocument(doc) {
            const preview = (doc?.pages || [])[0]?.preview;

            if(preview) return preview;
            return false;
        },

        rotateFile(filename) {
            const file = this.$store.state.loadedFiles.find(lf => lf.filename === filename);
            let angle = (file.angle || 0) + 90;
            
            if(angle >= 360) angle -= 360;
            file.angle = angle;
        },

        async processing(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            // TODO: если надо будет на сервак загружать, с какими то условиями

            this.$store.dispatch('loaderSetup', {
                isShow: true, textDescription: 'Compressing PDF file(s)...'
            });
            
            try {
                let file = await this.processLocalyTool();
                this.$store.commit('updateDownloadWindow', {
                    ...this.downloadWindowConfig, isShow: true, file,
                    documentName: this.documentName
                });
            } catch(err) {
                console.error(err);
            }

            this.$store.dispatch('loaderSetup', { isShow: false });
        },

        parseRotatedPages(file) {
            let _rotatedPages = {};
            for(let i = 0; i < file.numPages; i++) {
                let currentPage = file.pages.find(p => p.pn === i + 1);
                let angle = currentPage?.angle || file.angle || 0;

                if(angle > 1) _rotatedPages[i + 1] = angle;
            }

            return _rotatedPages;
        },
    }
};